import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/components/scrollbar/scrollbar.scss"
import "swiper/swiper.scss"
import Image from "../Image"
import { Link } from "gatsby"
import DownloadButton from "../buttons/DownloadButton"

function MobileSlider({
  sliders,
  headingClass = "u-h5 text-white",
  labelClass = "text-black",
}) {
  return (
    <div className="container px-0 overflow-visible">
      <Swiper
        className="flex flex-col w-full"
        slidesPerView={`auto`}
        grabCursor="1"
        style={{ overflow: `visible` }}
      >
        {sliders?.map((slider, i) => (
          <SwiperSlide
            key={`whitepaperSlider${i}`}
            className=" bg-white last:mr-0 max-h-[400px] max-w-[362px] !h-auto mr-7  "
            // style={{ height: "505px", width: "362px" }}
          >
            <Link to={slider?.uri} className="w-full h-full">
              <div className="w-full h-full cursor-pointer ">
                <Image
                  objectFit="cover"
                  className="w-full h-full"
                  image={
                    slider?.blogPost?.blogPost?.videoHero?.video
                      ?.previewImage ||
                    slider?.featuredImage?.node ||
                    slider?.image
                  }
                />
              </div>
              <div
                className={`mt-6 font-extrabold  uppercase ${headingClass} `}
              >
                {slider?.title ?? slider?.heading}
              </div>
              {slider?.downloadLabel && (
                <div className="mt-6">
                  <DownloadButton
                    file={slider?.download}
                    downloadText={slider?.downloadLabel}
                    labelClass={labelClass}
                  />
                </div>
              )}
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default MobileSlider
