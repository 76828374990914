import React from "react"
import styled from "styled-components"

const OffscreenRight = styled.div`
  @media screen and (min-width: 1280px) {
    max-width: calc(
      100vw - calc(((100vw) - calc(1280px - 3rem)) / 2)
    ) !important;
    padding-left: 1rem !important;
    padding-right: 0 !important;
    margin-left: calc(((100vw) - calc(1280px - 3rem)) / 2) !important;
  }
  @media screen and (max-width: 1279px) {
    max-width: calc(
      100vw - calc(((100vw) - calc(1024px - 3rem)) / 2)
    ) !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: calc(((100vw) - calc(1024px - 3rem)) / 2) !important;
  }
  @media screen and (max-width: 1024px) {
    max-width: calc(
      100vw - calc(((100vw) - calc(768px - 3rem)) / 2)
    ) !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: calc(((100vw) - calc(768px - 3rem)) / 2) !important;
  }
  @media screen and (max-width: 768px) {
    max-width: calc(
      100vw - calc(((100vw) - calc(640px - 3rem)) / 2)
    ) !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: calc(((100vw) - calc(640px - 3rem)) / 2) !important;
  }
  .swiper-container {
    overflow: visible;
  }
`

export default ({ children }) => {
  return <OffscreenRight className="container">{children}</OffscreenRight>
}
