import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { Link } from "gatsby"

import Image from "../Image"
import DownloadButton from "../buttons/DownloadButton"
import OffscreenRight from "../OffscreenRight"
import VideoModal from "../VideoModal"

function ExpandingSlider({ slider }) {
  const [activeSlide, setActiveSlide] = useState(0)

  return (
    <div className="h-full">
      <OffscreenRight>
        <div className="relative overflow-visible ">
          <div className="flex space-x-5 min-h-[700px] xl:min-h-[773px] ">
            {slider.map((slide, i) => (
              <motion.div
                key={slide?.image?.id}
                onClick={() => setActiveSlide(i)}
                initial={{
                  width: activeSlide === i ? "100%" : "25%",
                }}
                animate={{
                  width: activeSlide === i ? "100%" : "25%",
                }}
                transition={{ duration: 0.4, ease: "easeInOut" }}
                className={`cursor-pointer relative `}
              >
                <Image
                  objectFit="cover"
                  image={
                    slide?.image ||
                    slide?.blogPost?.blogPost?.videoHero?.video?.previewImage ||
                    slide?.featuredImage?.node
                  }
                  className="h-full w-full max-h-[574px] relative hover:opacity-70 transition-opacity duration-200"
                />
                <AnimatePresence>
                  {activeSlide === i && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{
                        duration: 0.4,
                        ease: "easeInOut",
                        delay: 0.3,
                      }}
                      className="absolute top-[32%] left-[46%] "
                    >
                      <VideoModal
                        playButton
                        {...slide?.blogPost?.blogPost?.videoHero?.video}
                        customVideo={
                          slide?.blogPost?.blogPost?.videoHero?.video
                            ?.videoEmbed
                        }
                      />
                    </motion.div>
                  )}
                </AnimatePresence>

                <AnimatePresence>
                  <motion.div className="mt-10">
                    {activeSlide === i && (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                          duration: 0.4,
                          ease: "easeInOut",
                          delay: 0.3,
                        }}
                        key={`heading${i}`}
                        className=" max-w-[586px]"
                      >
                        {slide?.uri ? (
                          <Link to={slide?.uri}>
                            <div className="text-28 leading-[1.269] font-extrabold uppercase mb-5">
                              {slide?.heading || slide?.title}
                            </div>
                          </Link>
                        ) : (
                          <div className="text-28 leading-[1.269] font-extrabold uppercase mb-5">
                            {slide?.heading || slide?.title}
                          </div>
                        )}

                        {slide?.downloadLabel && (
                          <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                              duration: 0.6,
                              ease: "easeInOut",
                              delay: 0.4,
                            }}
                          >
                            <DownloadButton
                              downloadText={slide?.downloadLabel}
                            />
                          </motion.div>
                        )}
                      </motion.div>
                    )}
                  </motion.div>
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        </div>
      </OffscreenRight>
    </div>
  )
}

export default ExpandingSlider
