import { motion } from "framer-motion"
import { Link } from "gatsby"
import moment from "moment"
import React, { useState } from "react"

function PressRelease({ i, uri, date, title }) {
  const [onHover, setOnHover] = useState(false)
  return (
    <Link to={uri}>
      <div
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        className="flex flex-col justify-between h-full"
      >
        <div>
          <div className="flex text-12 text-beige leading-[1.109] tracking-[0.05em] uppercase font-semibold">
            <div> {moment(date).format("MMMM DD YYYY")}</div>
          </div>
          <h5 className="mt-3 font-extrabold text-white uppercase u-h5 text-20 ">
            {title}
          </h5>
        </div>

        <motion.div
          animate={{
            width: onHover ? "80%" : "100%",
            backgroundColor: onHover ? "#A71930" : "#948A85",
          }}
          className="mt-7 h-2.5 bg-brown w-full"
        ></motion.div>
      </div>
    </Link>
  )
}

export default PressRelease
