import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Scrollbar } from "swiper"
import "swiper/components/scrollbar/scrollbar.scss"
import "swiper/swiper.scss"
import Image from "../Image"
import { Link } from "gatsby"

function WhitepaperSlider({ sliders }) {
  SwiperCore.use([Scrollbar])

  return (
    <div className="container px-0 overflow-visible">
      <Swiper
        className="flex flex-col w-full"
        slidesPerView={`auto`}
        grabCursor="1"
        style={{ overflow: `visible` }}
      >
        {sliders?.map((slider, i) => (
          <SwiperSlide
            key={`whitepaperSlider${i}`}
            className=" bg-white last:mr-0 max-h-[505px] max-w-[362px] !h-auto mr-7  "
            // style={{ height: "505px", width: "362px" }}
          >
            <Link to={slider?.uri} className="w-full h-full">
              <div className="relative w-full h-full cursor-pointer ">
                <Image objectFit="cover" image={slider?.featuredImage?.node} />
                <div className="absolute bottom-0 left-0 right-0 bg-red">
                  <div className="px-5 py-3 font-semibold text-white text-15">
                    {slider?.title}
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default WhitepaperSlider
