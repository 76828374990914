import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import moment from "moment"

import Image from "../Image"

function PostPreview({
  featuredImage,
  categories,
  date,
  title,
  titleClass,
  uri,
}) {
  return (
    <Link to={uri} className="flex flex-col">
      <div className="transition-opacity duration-300 hover:opacity-70">
        {featuredImage?.node ? (
          <div className="relative aspect-w-3 aspect-h-2 image-absolute">
            <Image
              objectFit="cover absolute image-absolute"
              image={featuredImage?.node}
            />
          </div>
        ) : (
          <StaticImage src={`../../assets/images/post-preview.jpg`} />
        )}
        <div className="mt-8">
          <div className="flex text-brown uppercase text-12 leading-[1.109] tracking-[0.05em] font-semibold">
            <div className="mr-6 ">{categories?.nodes[0]?.name || "event"}</div>
            <div> {moment(date).format("MMMM DD YYYY")}</div>
          </div>
          <div
            className={`mt-3 font-extrabold uppercase u-p1 text-20 leading-[1.269] ${titleClass} `}
          >
            {title ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. A leo amet id neque sit sit aliquam nulla."}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default PostPreview
