import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import moment from "moment"
import Seo from "gatsby-plugin-wpgraphql-seo"
import LoadMoreButton from "~/components/buttons/LoadMoreButton"
import { DownloadList } from "~/components/FlexibleLayouts/layouts/DownloadList"
import Image from "~/components/Image"
import Layout from "~/components/Layout"
import PressRelease from "~/components/resources/components/PressRelease"
import PostPreview from "~/components/resources/PostPreview"
import ExpandingSlider from "~/components/sliders/ExpandingSlider"
import MobileSlider from "~/components/sliders/MobileSlider"
import WhitepaperSlider from "~/components/sliders/WhitepaperSlider"

function News({ data: { wpPage, allWpPost, allWpCategory } }) {
  const { hero, featuredPosts, newsDownloadList } = wpPage.newsPage.news

  const [showMore, setShowMore] = useState(9)

  const whitepapers = allWpCategory.nodes.filter(
    cat => cat.name === "Whitepaper"
  )
  const events = allWpCategory.nodes.filter(cat => cat.name === "Event")
  const videos = allWpCategory.nodes.filter(cat => cat.name === "Video")
  const pressReleases = allWpCategory.nodes.filter(
    cat => cat.name === "Press Release"
  )

  return (
    <Layout headerBg="bg-transparent" dark={true} noise={false}>
      <Seo post={wpPage} />

      <section className="relative pt-32 pb-20 text-white md:pb-32 md:pt-56 bg-slate">
        <div className="container relative">
          <h1 className="text-center u-h1a text-32 md:text-h1-a md:max-w-4xl md:text-left">
            {hero?.heading}
          </h1>

          <div className="flex flex-wrap mt-12 -ml-12 md:mt-32">
            {featuredPosts?.slice(0, 2)?.map((post, i) => (
              <div
                key={`newsFeaturedTop${i}`}
                className="w-full pl-12 md:w-1/2 last:mt-12 md:last:mt-0"
              >
                <Link to={post?.uri}>
                  <div className="transition-opacity duration-300 hover:opacity-70 ">
                    <div className="aspect-w-144 aspect-h-95 max-h-[380px] relative image-absolute flex-none  ">
                      <Image
                        className="flex-none max-h-[380px]"
                        objectFit="cover absolute "
                        image={post?.featuredImage?.node}
                      />
                    </div>
                    <div className="mt-9 ">
                      <div className="flex text-12 text-beige leading-[1.109] tracking-[0.05em] uppercase font-semibold">
                        <div className="mr-7">
                          {post?.categories?.nodes[0].name}
                        </div>
                        <div>{moment(post?.date).format("MMMM DD YYYY")}</div>
                      </div>
                      <h4 className="mt-3 font-extrabold uppercase u-h4b text-22 md:text-28 ">
                        {post?.title}
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          <Link
            to={featuredPosts[2]?.uri}
            className="flex flex-wrap items-end mt-12 -ml-12 transition-opacity duration-300 md:mt-28 hover:opacity-70"
          >
            <div className="w-full md:w-[70%] pl-12">
              <div className="bg-black min-h-[130px]">
                <Image
                  className="max-h-[603px]"
                  objectFit="cover"
                  image={featuredPosts[2]?.featuredImage?.node}
                />
              </div>
            </div>

            <div className="w-full md:w-[30%] pl-12">
              <div className="mt-9">
                <div className="flex text-12 text-beige leading-[1.109] tracking-[0.05em] uppercase font-semibold">
                  <div className="mr-7">
                    {featuredPosts[2]?.categories?.nodes[0].name}
                  </div>
                  <div>
                    {moment(featuredPosts[2]?.date).format("MMMM DD YYYY")}
                  </div>
                </div>
                <h4 className="mt-3 font-extrabold uppercase u-h4b text-22 md:text-28 ">
                  {featuredPosts[2]?.title}
                </h4>
              </div>
            </div>
          </Link>
        </div>
      </section>

      {pressReleases[0]?.posts?.nodes?.length > 0 && (
        <section className="relative pb-20 md:pb-32 bg-slate">
          <div className="container relative">
            <h3 className="text-white u-h3">Press Releases</h3>
            <div className="flex flex-wrap mt-12 -ml-6 md:mt-20 ">
              {pressReleases[0]?.posts?.nodes?.map((press, i, arr) => (
                <div
                  key={`pressRelease${i}`}
                  className={`w-full pl-6 md:w-1/2 lg:w-1/3 mb-12 md:mb-0 t ${
                    i === arr.length - 1 && "mb-0"
                  } ${i >= arr.length - 3 && "lg:mt-12"} ${
                    i >= arr.length - 4 && "md:mt-12 lg:mt-0"
                  } `}
                >
                  <PressRelease {...press} />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      {videos[0]?.posts?.nodes?.length > 0 && (
        <section className="pt-20 overflow-hidden bg-black pb-[17rem] md:py-32 md:overflow-auto">
          <div className="container">
            <h3 className="text-white u-h3">Videos</h3>
            <div className="block mt-20 text-white md:hidden">
              <MobileSlider
                sliders={videos[0]?.posts?.nodes
                  ?.slice(0, 3)
                  .sort((a, b) => new Date(b.date) - new Date(a.date))}
              />
            </div>
          </div>
          <div className="hidden mt-20 text-white md:block">
            <ExpandingSlider
              slider={videos[0]?.posts?.nodes
                ?.slice(0, 3)
                .sort((a, b) => new Date(b.date) - new Date(a.date))}
            />
          </div>
        </section>
      )}

      <DownloadList {...newsDownloadList} />

      {whitepapers[0]?.posts?.nodes?.length > 0 && (
        <section className="relative py-20 overflow-hidden bg-slate md:py-32 ">
          <div className="container relative">
            <h3 className="text-white u-h3">Whitepapers</h3>

            <div className="mt-16 ">
              <WhitepaperSlider sliders={whitepapers[0]?.posts?.nodes} />
            </div>
          </div>
        </section>
      )}

      {events[0]?.posts?.nodes?.length > 0 && (
        <section className="relative py-20 md:py-32 bg-slate ">
          <div className="container relative">
            <h3 className="text-white u-h3">Events</h3>
            <div className="flex flex-wrap ml-[-2.875rem] mt-12 md:mt-20">
              <div className="pl-[2.875rem] w-full md:w-1/2">
                <Link to={events[0]?.posts?.nodes[0]?.uri}>
                  <div className="transition-opacity duration-300 hover:opacity-70">
                    <Image
                      image={events[0]?.posts?.nodes[0]?.featuredImage?.node}
                    />

                    <div className="mt-9">
                      <div className="flex text-12 text-beige leading-[1.109] tracking-[0.05em] uppercase font-semibold">
                        <div className="mr-7">
                          {
                            events[0]?.posts?.nodes[0]?.categories?.nodes[0]
                              .name
                          }
                        </div>
                        <div>
                          {moment(events[0]?.posts?.nodes[0]?.date).format(
                            "MMMM DD YYYY"
                          )}
                        </div>
                      </div>
                      <h4 className="mt-3 font-extrabold text-white uppercase u-h4b">
                        {events[0]?.posts?.nodes[0]?.title}
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="pl-[2.875rem] w-full md:w-1/2 md:-mt-14  ">
                {events[0]?.posts?.nodes?.slice(1, 5)?.map((event, i, arr) => (
                  <Link key={`event${i}`} to={event?.uri}>
                    <div
                      key={`newsEvent${i}`}
                      className="flex items-start transition-opacity duration-300 pt-14 hover:opacity-70"
                    >
                      <Image
                        className="max-w-[6.125rem] md:h-[6.125rem] mr-4 md:mr-8  w-full"
                        objectFit="cover"
                        image={event?.featuredImage?.node}
                      />

                      <div className="">
                        <div className="flex text-12 text-beige leading-[1.109] tracking-[0.05em] uppercase font-semibold">
                          <div className="mr-7">
                            {event?.categories?.nodes[0].name}
                          </div>
                          <div>
                            {" "}
                            {moment(event?.date).format("MMMM DD YYYY")}
                          </div>
                        </div>
                        <h4 className="u-p2 font-extrabold md:text-20 mt-3 leading-[1.269] text-white uppercase">
                          {event?.title}
                        </h4>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="relative pt-20 pb-20 bg-black border-b md:pb-32 border-slate">
        <div className="container relative">
          <h3 className="text-white u-h3">All resources</h3>

          <div className="flex flex-wrap mt-20 -ml-14 ">
            {allWpPost?.nodes?.slice(0, showMore)?.map((allPost, i) => (
              <div
                key={`allPost${i}`}
                className="w-full mb-16 md:w-1/2 lg:w-1/3 pl-14"
              >
                <PostPreview {...allPost} titleClass="text-white" />
              </div>
            ))}
          </div>

          <div className="flex justify-center">
            {allWpPost?.nodes.length > showMore && (
              <LoadMoreButton
                className="!bg-transparent border border-white rounded-full border-opacity-30"
                onClick={() => setShowMore(showMore + 3)}
              />
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default News

export const pageQuery = graphql`
  query News($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      newsPage {
        news {
          hero {
            heading
          }
          loadMore {
            label
          }
          featuredPosts {
            ... on WpPost {
              title
              featuredImage {
                node {
                  ...Image
                }
              }
              categories {
                nodes {
                  name
                }
              }
              date
              uri
            }
          }
          newsDownloadList {
            downloads {
              label
              download {
                year
                title
                file {
                  localFile {
                    publicURL
                    url
                  }
                }
                rns {
                  localFile {
                    publicURL
                    url
                  }
                }
                presentation {
                  localFile {
                    publicURL
                    url
                  }
                }
                webcast
              }
            }
            section {
              backgroundColour
              id
              fieldGroupName
              sectionDecoration
            }
            layout
            coreCopyStack {
              text
              subheading
              heading
              links {
                videoEmbed
                type
                icon
                email
                behaviour
                file {
                  localFile {
                    publicURL
                  }
                }
                link {
                  title
                  url
                }
              }
            }
          }
        }
      }
    }

    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        title
        featuredImage {
          node {
            ...Image
          }
        }
        categories {
          nodes {
            name
          }
        }
        date
        uri
      }
    }

    allWpCategory(sort: { fields: posts___nodes___date, order: DESC }) {
      nodes {
        name
        id
        uri
        posts {
          nodes {
            title
            uri
            date
            excerpt
            categories {
              nodes {
                name
              }
            }

            featuredImage {
              node {
                ...Image
              }
            }
            blogPost {
              blogPost {
                videoHero {
                  video {
                    previewImage {
                      ...Image
                    }
                    videoEmbed
                    channel
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
